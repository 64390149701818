import React from 'react'

const thesis = () => {
  return (
    <div>
     
      
    </div>
  )
}

export default thesis
